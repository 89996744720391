<template>
    <c-panel v-if="requests">
        <c-grid class="widths-100-all">
            <template v-for="request in requests" :key="request.id">
                <c-grid-item v-if="request.data">
                    <c-card
                        class="request pr-1"
                        :class="request.data.status?.toLowerCase()"
                        @click="$router.push('/request/' + request.id)"
                    >
                        <c-grid class="f-align-center spacing-sm-all">
                            <c-grid-item>
                                <div class="status"></div>
                            </c-grid-item>
                            <c-grid-item class="width-grow-all">
                                <p class="text-bold">{{ request.data.name }}</p>
                            </c-grid-item>
                            <c-grid-item>
                                <c-button-group class="merged">
                                    <c-button
                                        class="green"
                                        @click="markResponded(request)"
                                        :disabled="!canRespond(request)"
                                    >
                                        <template #icon>
                                            <i class="fas fa-check"></i>
                                        </template>
                                    </c-button>
                                    <c-button
                                        class="red"
                                        @click="cancelRequest(request)"
                                        :disabled="!canCancel(request)"
                                    >
                                        <template #icon>
                                            <i class="fas fa-times"></i>
                                        </template>
                                    </c-button>
                                </c-button-group>
                            </c-grid-item>
                        </c-grid>
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, watch } from "vue";
import { Database } from "vuebase";
import { ServiceRequest } from "../main";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const requests = database?.collection<ServiceRequest>("requests").documents();

        watch(
            () => requests,
            () => {
                requests?.sort((a, b) => {
                    return (b.data?.dateCreated?.seconds ?? 0) - (a.data?.dateCreated?.seconds ?? 0);
                });
            },
            { deep: true },
        );

        function cancelRequest(request: Database.Document<ServiceRequest>): Promise<void> {
            if (request?.data) {
                const result = window.confirm("Are you sure you want to cancel this request?");

                if (result) {
                    request.data.status = "Cancelled";
                    return request.save().then();
                }
            }
            return Promise.reject();
        }

        function markResponded(request: Database.Document<ServiceRequest>): Promise<void> {
            if (request?.data) {
                request.data.status = "Responded";
                request.data.dateResponded =
                    Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp;

                return request.save();
            }

            return Promise.reject();
        }

        function canRespond(request: Database.Document<ServiceRequest>): boolean {
            return request.data?.status == "New" || request.data?.status == "Editing";
        }

        function canCancel(request: Database.Document<ServiceRequest>): boolean {
            return request.data?.status != "Responded" && request.data?.status != "Cancelled";
        }

        return {
            requests,
            markResponded,
            cancelRequest,
            canRespond,
            canCancel,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~coho-ui/src/styles/variables.scss";

.request {
    flex-wrap: nowrap;

    .grid-item {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .status {
        width: 10px;
        height: 40px;
    }

    &.new,
    &.editing {
        background-color: rgba($color-yellow, 0.2);

        .status {
            background-color: $color-yellow;
        }
    }

    &.cancelled {
        background-color: rgba($color-red, 0.2);
        text-decoration: line-through;
        text-decoration-color: $color-red;

        .status {
            background-color: $color-red;
        }
    }

    &.responded {
        background-color: rgba($color-green, 0.2);

        .status {
            background-color: $color-green;
        }
    }
}
</style>
