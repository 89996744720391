
import { inject, watch } from "vue";
import { Database } from "vuebase";
import { ServiceRequest } from "../main";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const requests = database?.collection<ServiceRequest>("requests").documents();

        watch(
            () => requests,
            () => {
                requests?.sort((a, b) => {
                    return (b.data?.dateCreated?.seconds ?? 0) - (a.data?.dateCreated?.seconds ?? 0);
                });
            },
            { deep: true },
        );

        function cancelRequest(request: Database.Document<ServiceRequest>): Promise<void> {
            if (request?.data) {
                const result = window.confirm("Are you sure you want to cancel this request?");

                if (result) {
                    request.data.status = "Cancelled";
                    return request.save().then();
                }
            }
            return Promise.reject();
        }

        function markResponded(request: Database.Document<ServiceRequest>): Promise<void> {
            if (request?.data) {
                request.data.status = "Responded";
                request.data.dateResponded =
                    Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp;

                return request.save();
            }

            return Promise.reject();
        }

        function canRespond(request: Database.Document<ServiceRequest>): boolean {
            return request.data?.status == "New" || request.data?.status == "Editing";
        }

        function canCancel(request: Database.Document<ServiceRequest>): boolean {
            return request.data?.status != "Responded" && request.data?.status != "Cancelled";
        }

        return {
            requests,
            markResponded,
            cancelRequest,
            canRespond,
            canCancel,
        };
    },
};
